import { gql } from '@apollo/client'

// resolver
// content
// contentType
export const NodeFields = gql`
  fragment NodeFields on Node {
    name
    decrypted
    parent
    parentOwner
    owner
    label
    addr
  }
`

export const SubDomainStateFields = gql`
  fragment SubDomainStateFields on SubDomain {
    label
    domain
    name
    owner
    price
    rent
    referralFeePPM
    available
    state
  }
`
