import { getNetwork, getNetworkId, isReadOnly } from '@ringsnetwork/bns-util'
import '@openblockhq/dappsdk'

import { setup as setupENS } from '../apollo/mutations/ens'
import {
  isReadOnlyReactive,
  networkIdReactive,
  networkReactive,
  web3ProviderReactive
} from '../apollo/reactiveVars'

const INFURA_ID = '9aa3d95b3bc440fa88ea12eaa4456161'

let provider

const option = {
  network: 'mainnet', // optional
  cacheProvider: true, // optional
  providerOptions: {
    walletconnect: {
      package: () => import('@walletconnect/web3-provider'),
      packageFactory: true,
      options: {
        infuraId: INFURA_ID
      }
    },
    walletlink: {
      package: () => import('walletlink'),
      packageFactory: true,
      options: {
        appName: 'Bitcoin name service',
        jsonRpcUrl: `https://mainnet.infura.io/v3/${INFURA_ID}`
      }
    },
    torus: {
      package: () => import('@toruslabs/torus-embed'),
      packageFactory: true
    },
    'custom-openblock': {
      display: {
        logo: 'https://docs.openblock.com/img/logo.svg',
        name: 'Openblock',
        description: ''
      },
      package: window.openblock,
      options: {},
      connector: async (ProviderPackage, options) => {
        const provider = window.openblock

        await provider.enable()

        return provider
      }
    }
  },
  theme: {
    background: '#fff',
    hover: 'rgba(255, 91, 0, 0.1)'
  }
}

let web3Modal

export const connect = async () => {
  try {
    const Web3Modal = (await import('@ensdomains/web3modal')).default

    web3Modal = new Web3Modal(option)
    provider = await web3Modal.connect()

    // FIXME
    // // switch network in development
    // try {
    //   await provider.request({
    //     method: 'wallet_switchEthereumChain',
    //     params: [{ chainId: '0x1' }]
    //   })
    // } catch (switchError) {
    //   console.log('Cannot switch to the network')
    // }

    await setupENS({
      customProvider: provider,
      reloadOnAccountsChange: false,
      enforceReload: true
    })

    return provider
  } catch (e) {
    if (e !== 'Modal closed by user') {
      throw e
    }
  }
}

export const disconnect = async function() {
  if (web3Modal) {
    await web3Modal.clearCachedProvider()
  }

  // Disconnect wallet connect provider
  if (provider && provider.disconnect) {
    provider.disconnect()
  }

  await setupENS({
    reloadOnAccountsChange: false,
    enforceReadOnly: true,
    enforceReload: false
  })

  isReadOnlyReactive(isReadOnly())
  web3ProviderReactive(null)
  networkIdReactive(await getNetworkId())
  networkReactive(await getNetwork())
}

export const setWeb3Modal = x => {
  web3Modal = x
}
