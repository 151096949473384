import React, { Fragment, useEffect } from 'react'

import Main from './Main'

import Header from '../Theme/Header'
import MobileHeader from '../Theme/MobileHeader'
import Footer from '../Theme/Footer'

const DefaultLayout = ({ children }) => {
  return (
    <Fragment>
      <Header />
      <MobileHeader />
      <Main>{children}</Main>
      <Footer />
    </Fragment>
  )
}

export default DefaultLayout
