import styled from '@emotion/styled/macro'
import { Link } from 'react-router-dom'

import mq from 'mediaQuery'

import Nav from './Nav'
import NetworkInformation from './NetworkInformation'

import BNSLogo from '../../assets/logo.svg'

const HeaderContainer = styled('header')`
  display: none;
  justify-content: flex-start;
  align-items: center;

  ${mq.xLarge`
    justify-content: space-around;
    align-items: center;
  `}

  ${mq.small`
    display: flex;
    `}
`

const HeaderContent = styled('div')`
  max-width: 100vw;
  padding: 20px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-around;

  ${mq.small`
    padding: 48px 20px 30px;
    max-width: 1000px;
  `}
`

const Logo = styled('div')`
  display: none;

  ${mq.medium`
    display: block;
  `}
`

const IconLogo = styled('img')`
  width: 75px;
  margin-right: 70px;
`

const NetworkContainer = styled('div')`
  // display: none;
  display: block;

  ${mq.medium`
    display: block;
    position: absolute;
    right: 20px;
    top: 50px;
  `}
`

const Header = () => {
  return (
    <HeaderContainer>
      <HeaderContent>
        <Logo>
          <Link to="/">
            <IconLogo src={BNSLogo} />
          </Link>
        </Logo>
        <Nav />
        <NetworkContainer>
          <NetworkInformation />
        </NetworkContainer>
      </HeaderContent>
    </HeaderContainer>
  )
}

export default Header
