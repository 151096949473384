import { useState } from 'react'
import styled from '@emotion/styled/macro'
import { Link } from 'react-router-dom'

import mq from 'mediaQuery'

import Nav from './MobileNav'
import NetworkInformation from './NetworkInformation'

import BNSLogo from '../../assets/logo.svg'

const HeaderContainer = styled('header')`
  margin-bottom: 24px;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: rgba(255, 255, 255, 1);
  overflow: hidden;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

  ${mq.xLarge`
    justify-content: space-around;
    align-items: center;
  `}

  ${mq.small`
    display: none;
  `}
`

const HeaderContent = styled('div')`
  width: 100%;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;

  ${mq.small`
    padding: 48px 20px 30px;
    max-width: 1000px;
  `}
`

const Logo = styled('div')`
  display: block;
`

const IconLogo = styled('img')`
  width: 75px;
  margin-right: 70px;
`

const NetworkContainer = styled('div')`
  // display: none;
  display: block;

  ${mq.medium`
    display: block;
    position: absolute;
    right: 20px;
    top: 50px;
  `}
`

const MobileHeader = () => {
  const [open, setOpen] = useState(false)

  return (
    <HeaderContainer>
      <HeaderContent>
        <Logo>
          <Link to="/">
            <IconLogo src={BNSLogo} />
          </Link>
        </Logo>
        <div onClick={() => setOpen(!open)}>
          {!open ? (
            <img
              style={{
                width: '28px',
                height: '28px'
              }}
              src="https://api.iconify.design/ri:menu-3-line.svg"
            />
          ) : (
            <img
              style={{
                width: '32px',
                height: '32px'
              }}
              src="https://api.iconify.design/ri:close-line.svg"
            />
          )}
        </div>

        <div
          style={{
            position: 'absolute',
            top: '76px',
            left: 0,
            right: 0,
            bottom: 0,
            display: open ? 'flex' : 'none',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '12px',
            backgroundColor: '#fff',
            zIndex: 9999,
            paddingTop: '12px'
          }}
          onClick={() => setOpen(!open)}
        >
          <Nav />
          <NetworkContainer>
            <NetworkInformation />
          </NetworkContainer>
        </div>
      </HeaderContent>
    </HeaderContainer>
  )
}

export default MobileHeader
