import styled from '@emotion/styled/macro'

import LanguageSwitcher from '../LanguageSwitcher'

import ElementLogo from '../../assets/element_logo.svg'
import OpenseaLogo from '../../assets/opensea_logo.svg'
import X2y2Logo from '../../assets/x2y2_logo.svg'
import OkxLogo from '../../assets/okx.png'

const FooterContainer = styled('footer')`
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #2c2c3d;
  padding: 20px;
`

const FooterContent = styled('div')`
  max-width: 1000px;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
`

const Wrap = styled('div')`
  margin-top: 10px;
  text-align: center;
  display: flex;
  align-items: center;
`

const Site = styled('div')`
  margin-right: 10px;
  flex-shrink: 0;
`

const NFT = styled(`div`)`
  display: flex;
  align-items: center;
`
const IconLogo = styled('img')`
  height: 20px;
  margin-left: 10px;
`

const Footer = () => {
  return (
    <FooterContainer>
      <FooterContent>
        <NFT>
          Trade BNS domains on:
          <a
            href="https://opensea.io/collection/bnsbtc"
            target="_blank"
            rel="noopener noreferrer"
            title="OpenSea"
          >
            <IconLogo src={OpenseaLogo} />
          </a>
          <a
            href="https://x2y2.io/collection/bnsbtc/items"
            target="_blank"
            rel="noopener noreferrer"
            title="X2Y2"
          >
            <IconLogo src={X2y2Logo} />
          </a>
          <a
            href="https://element.market/collections/bnsbtc"
            target="_blank"
            rel="noopener noreferrer"
            title="Element"
          >
            <IconLogo src={ElementLogo} />
          </a>
          <a
            href="https://www.okx.com/cn/web3/nft/markets/collection/eth/bns-bitcoin-network-system"
            target="_blank"
            rel="noopener noreferrer"
            title="OKX NFT"
          >
            <IconLogo src={OkxLogo} />
          </a>
        </NFT>
        <Wrap>
          <Site>BNS Labs &copy; 2022 - 2023 All Rights Reserved</Site>
          <LanguageSwitcher />
        </Wrap>
      </FooterContent>
    </FooterContainer>
  )
}

export default Footer
