import React, { useState } from 'react'
import styled from '@emotion/styled/macro'

import NoAccounts from './NoAccounts'

const NoAccountContainer = styled('div')``

export default ({ colour, buttonText, onClick, textColour, className, text }) => {
  const [showModal] = useState(false)

  return (
    <NoAccountContainer className={className}>
      <NoAccounts
        colour={colour}
        buttonText={buttonText}
        textColour={textColour}
        active={showModal}
        onClick={onClick}
        text={text}
      />
    </NoAccountContainer>
  )
}
