import React from 'react'
import styled from '@emotion/styled/macro'

import { hasNonAscii } from '../../utils/utils'

const MainContainer = styled('main')`
  display: flex;
  justify-content: space-around;
  min-height: calc(100vh - 240px);
  padding: 0 15px;
`

const MainContent = styled('div')`
  max-width: 800px;
  width: 100%;
`

const Main = ({ children }) => (
  <MainContainer hasNonAscii={hasNonAscii()}>
    <MainContent>{children}</MainContent>
  </MainContainer>
)

export default Main
