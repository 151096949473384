import React from 'react'
import styled from '@emotion/styled/macro'

import IconConnect from '../../../assets/icon-connect.svg'
import IconDisconnect from '../../../assets/icon-disconnect.svg'

const NoAccountsContainer = styled('div')`
  padding: 10px 20px;
  border-radius: 80px;
  border: 1px solid #ff5800;
  background: ${({ active }) => (active ? 'transparent' : '#FF5B00')};
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ active }) => (active ? 'auto' : '150px')};
  transition: 0.2s;

  span {
    color: ${({ active, colour }) => (active ? '#FF5B00' : '#fff')};
  }

  &:hover {
    cursor: ${({ onClick }) => (onClick ? 'pointer' : 'auto')};
  }
`

const SVG = styled('img')`
  margin-right: 10px;
  width: 20px;
  height: 20px;
  display: block;
`

const NoAccounts = ({
  className,
  colour = '#ffffff',
  textColour,
  onClick,
  buttonText,
  active,
  text
}) => (
  <NoAccountsContainer
    colour={colour}
    className={className}
    onClick={onClick}
    active={active}
    textColour={textColour}
  >
    <SVG src={active ? IconDisconnect : IconConnect} />
    <span>{buttonText}{text ? ` ${text}`: ''}</span>
  </NoAccountsContainer>
)

export default NoAccounts
