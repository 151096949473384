import { injectGlobal } from 'emotion'

import bg from './assets/body.svg'

injectGlobal`
  * {
    box-sizing: border-box;
  }

  body {
    font-family: Montserrat;
    background-color: #f5f5f5;
    background: radial-gradient(circle farthest-corner at bottom right, #F3E7DE 0%, #F5F5F5 40%);
    margin: 0;
    min-height: 100vh;
  }

  #root {
    min-height: 100vh;
    background: url(${bg}) left top no-repeat;
    background-size: auto 100vh;
    background-attachment: fixed;
  }

  a {
    text-decoration: none;
  }

  .web3modal-modal-card {
    display: block !important;
    max-width: 460px !important; 
    padding: 25px 0 17px !important;
  }

  .web3modal-provider-wrapper {
    flex-direction: row !important;
    padding: 0px 35px !important;
    margin-bottom: 8px !important;
  }

  .web3modal-provider-container {
    flex-direction: row !important;
    justify-content: flex-start !important;
    border: 1px solid #fff;
    padding: 15px 35px !important;
  }

  .web3modal-provider-container:hover {
    border: 1px solid #FF5B00;
    background-color: border: rgba(195, 195, 195, 0.14);
  }

  .web3modal-provider-name {
    width: auto !important;
    margin-left: 20px !important;
    margin-top: 0 !important;
  }

  .web3modal-provider-description {
    display: none;
  }

  .flex {
    display: flex;
    margin-bottom: 2rem;
  } 

  .justify-around {
    justify-content: space-between;
  }

  .items-center {
    align-items: center;
  }

  .space-x-1 {
    margin-left: 0.25rem;
  }

  .w-75px {
    width: 88px;
    box-sizing: boder-box;
  }

  .font-bold {
    font-weight: bold;
    padding: 2rem 0;
    width: 100%;
    text-align: center;
  }

  .font-semibold {
    font-weight: 600;
  }

  .text-lg {
    font-size: 1.125rem; /* 18px */
    line-height: 1.75rem; /* 28px */
  }

  .text-sm {
    font-size: 0.875rem; /* 14px */
    line-height: 1.25rem; /* 20px */
  }

  .shadow-md {
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    border: 1px solid rgb(134 140 160 / 2%);
  }

  .text-888 {
    color: #888;
  }

  .text-center {
    text-align: center;
  }

  .mt-2 {
    margin-top: 1rem
  }

  .rounded-lg {
    border-radius: 0.5rem; /* 8px */
  }

  .bg-2ed8a7 {
    background-color: #fafafa;
  }

  @media (min-width: 768px) {
    .w-75px {
      width: 188px;
    }

    .font-bold {
      padding: 4rem 0;
    }

    .text-lg {
      font-size: 3rem; /* 48px */
      line-height: 1;
    }
  }
`
