import { Fragment } from 'react'
import { Link, withRouter } from 'react-router-dom'
import styled from '@emotion/styled/macro'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import mq from 'mediaQuery'

const NavLink = styled(Link)`
  font-size: 16px;
  color: #2c2c3d;
  font-weight: ${p => (p.active ? '600' : '300')};
  position: relative;
  white-space: nowrap;

  ${mq.small`
    margin-bottom: 5px;
    margin-right: 30px;
  `}

  ${mq.xLarge`
    margin-right: 60px;
    margin-bottom: 0px;
    font-size: 16px;
  `}

  &:visited {
    color: #2c2c3d;
  }

  &:after {
    content: '';
    position: absolute;
    left: 50%;
    top: calc(100% + 10px);
    background-color: #ff5b00;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    display: ${p => (p.active ? 'block' : 'none')};

    @media (max-width: 576px) {
      display: none;
    }
  }
`

const OutLink = styled('a')`
  font-size: 16px;
  color: #2c2c3d;
  font-weight: ${p => (p.active ? '600' : '300')};
  position: relative;
  white-space: nowrap;

  ${mq.small`
    margin-bottom: 5px;
    margin-right: 30px;
  `}

  ${mq.xLarge`
    margin-right: 60px;
    font-size: 16px;
  `}

  &:visited {
    color: #2c2c3d;
  }
`

const SIDENAV_QUERY = gql`
  query getSideNavData {
    accounts
    isReadOnly
  }
`

const Nav = ({ match }) => {
  const { url } = match
  const { t } = useTranslation()

  const {
    data: { accounts, isReadOnly }
  } = useQuery(SIDENAV_QUERY)

  return (
    <Fragment>
      <NavLink to="/" active={url === '/' ? 1 : 0}>
        {t('c.search')}
      </NavLink>
      {accounts?.length > 0 && !isReadOnly ? (
        <NavLink
          active={url === '/address/' + accounts[0] ? 1 : 0}
          to={'/address/' + accounts[0]}
        >
          <span>{t('c.mynames')}</span>
        </NavLink>
      ) : null}
      <NavLink active={url === '/favourites' ? 1 : 0} to="/favourites">
        <span>{t('c.favourites')}</span>
      </NavLink>

      <NavLink active={url === '/faq' ? 1 : 0} to="/faq">
        <span>{t('c.faq')}</span>
      </NavLink>
      <OutLink
        href="https://medium.com/@ringsnetwork/token-mining-on-btc-of-bns-64232f8a83b9"
        target="_blank"
        rel="noopener noreferrer"
      >
        {t('c.mining')}
      </OutLink>
      <OutLink
        href="https://medium.com/@ringsnetwork/introduction-to-bns-e2cd3eeb7b4b"
        target="_blank"
        rel="noopener noreferrer"
      >
        {t('c.about')}
      </OutLink>
    </Fragment>
  )
}

export default withRouter(Nav)
