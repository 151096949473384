import React from 'react'
import styled from '@emotion/styled/macro'
import { useTranslation } from 'react-i18next'
import { gql } from '@apollo/client'
import mq from 'mediaQuery'
import { useQuery } from '@apollo/client'

import UnstyledBlockies from '../Blockies'
import NoAccountsModal from './NoAccounts/NoAccountsModal'
import NoAccounts from './NoAccounts/NoAccounts'

import { GET_REVERSE_RECORD } from '../../graphql/queries'
import { connectProvider, disconnectProvider } from '../../utils/providerUtils'

const NetworkInformationContainer = styled('div')``

const NetworkStatus = styled('div')`
  color: #cacaca;
  font-size: 14px;
  text-transform: capitalize;
  font-weight: 100;
  margin-top: -2px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  position: absolute;
  top: -46px;
  right: 50px;

  &:before {
    content: '';
    display: flex;
    width: 6px;
    height: 6px;
    border-radius: 3px;
    background: #ff5b00;
    margin-right: 5px;
  }

  ${mq.medium`
    right: 0;
    top: -20px;
  `}
`

const AccountContainer = styled('div')`
  // display: flex;
  margin-top: 20px;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  ${mq.medium`
    margin-top: unset;
    flex-direction: row;
    gap: unset;
    align-items: unset;
  `}
`

const NETWORK_INFORMATION_QUERY = gql`
  query getNetworkInfo @client {
    accounts
    isReadOnly
    isSafeApp
    avatar
    network
    displayName
  }
`

function NetworkInformation() {
  const { t } = useTranslation()
  const {
    data: { accounts, isSafeApp, network, displayName, isReadOnly }
  } = useQuery(NETWORK_INFORMATION_QUERY)

  const {
    data: { getReverseRecord } = {},
    loading: reverseRecordLoading
  } = useQuery(GET_REVERSE_RECORD, {
    variables: {
      address: accounts?.[0]
    },
    skip: !accounts?.length
  })

  return (
    <NetworkInformationContainer hasAccount={accounts && accounts.length > 0}>
      {!isReadOnly ? (
        <AccountContainer>
          {/* {!reverseRecordLoading &&
          getReverseRecord &&
          getReverseRecord.avatar ? (
            <Avatar
              src={imageUrl(getReverseRecord.avatar, displayName, network)}
            />
          ) : (
            <Blockies address={accounts[0]} imageSize={45} />
          )} 
          <Account data-testid="account" className="account">
            <span>{displayName}</span>
          </Account> */}
          <NetworkStatus>{network}</NetworkStatus>
          {!isSafeApp && (
            <NoAccounts
              onClick={disconnectProvider}
              buttonText={t('c.disconnect')}
              colour={'#F5A623'}
              text={`${displayName}`}
              active
            />
          )}
        </AccountContainer>
      ) : (
        <AccountContainer>
          <NoAccountsModal
            onClick={connectProvider}
            colour={'#F5A623'}
            buttonText={t('c.connect')}
          />
        </AccountContainer>
      )}
    </NetworkInformationContainer>
  )
}
export default NetworkInformation
